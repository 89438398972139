import 'bootstrap/dist/css/bootstrap.min.css';
import AUTH_CONTEXT from 'GuardianAuth/lib/auth/AuthContext';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import createGuardianAuthStore from 'GuardianAuth/lib/store/guardianAuthStore';
import React from 'react';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import {
    API_GATEWAY_API_KEY,
    COGNITO_APP_WEB_DOMAIN,
    COGNITO_CLIENT_ID,
    COGNITO_REDIRECT_TO,
    COGNITO_USERPOOL,
    STAGE
} from './config/globals';
import './css/index.css';
import './css/App.css';
import './css/Announcements.css';
import './css/Model.css';
import './css/IncidentContacts.css';
import * as serviceWorker from './registerServiceWorker';
import { AwsRum } from 'aws-rum-web';

const title = 'Guardian Facility Management Widget' + STAGE === 'dev' || STAGE === 'beta' ? ' BETA' : '';

// RUM metric tracking init based on STAGE
try {
    let config, APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION;
    switch (STAGE) {
        case 'beta': {
            config = {
                sessionSampleRate: 1,
                guestRoleArn:
                    'arn:aws:iam::559445041357:role/RUM-Monitor-us-east-1-559445041357-0515273366661-Unauth',
                identityPoolId: 'us-east-1:05bd87d0-0428-4440-9c57-4aeb8c53c734',
                endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
                telemetries: ['performance', 'errors', 'http'],
                allowCookies: true,
                enableXRay: true
            };
            APPLICATION_ID = '757540ef-cc05-4411-9647-0615e2149dda';
            APPLICATION_VERSION = '1.0.0';
            APPLICATION_REGION = 'us-east-1';
            break;
        }
        case 'gamma': {
            config = {
                sessionSampleRate: 1,
                guestRoleArn:
                    'arn:aws:iam::499140376052:role/RUM-Monitor-us-east-1-499140376052-7318477276661-Unauth',
                identityPoolId: 'us-east-1:0d44a2ef-c3c6-4653-82ba-9cfe95309728',
                endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
                telemetries: ['performance', 'errors', 'http'],
                allowCookies: true,
                enableXRay: true
            };
            APPLICATION_ID = '2cac3575-c018-46a5-9376-cb0a04ef5b2f';
            APPLICATION_VERSION = '1.0.0';
            APPLICATION_REGION = 'us-east-1';
            break;
        }
        case 'prod': {
            config = {
                sessionSampleRate: 1,
                guestRoleArn:
                    'arn:aws:iam::466210282594:role/RUM-Monitor-us-east-1-466210282594-7156424366661-Unauth',
                identityPoolId: 'us-east-1:4af54328-8da7-496f-acbd-0555de57eec8',
                endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
                telemetries: ['performance', 'errors', 'http'],
                allowCookies: true,
                enableXRay: true
            };
            APPLICATION_ID = 'b5cc8f6a-28af-48ad-84e3-4bbd36f22c0e';
            APPLICATION_VERSION = '1.0.0';
            APPLICATION_REGION = 'us-east-1';
            break;
        }
        default:
            console.log(`Cannot init RUM due to unrecognized stage: ${STAGE}`);
    }
    // eslint-disable-next-line
    const awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
} catch (error) {
    // eslint-disable-next-line
    console.log(`Failed to init RUM metrics: ${error}`);
}

GuardianAuth.init(
    createGuardianAuthStore(),
    COGNITO_APP_WEB_DOMAIN,
    COGNITO_CLIENT_ID,
    COGNITO_USERPOOL,
    API_GATEWAY_API_KEY,
    COGNITO_REDIRECT_TO
);
GuardianAuth.setTitle(title);
GuardianAuth.debug = STAGE === 'dev' || STAGE === 'beta';

function showPage(guardianAuthStore) {
    ReactDOM.render(
        <Provider store={guardianAuthStore} context={AUTH_CONTEXT}>
            <App />
        </Provider>,
        document.getElementById('root')
    );
    serviceWorker.unregister();
}

if (GuardianAuth.guardianAuthStore) {
    GuardianAuth.authAndShowPage(() => showPage(GuardianAuth.guardianAuthStore));
}
